@import "~antd/dist/antd.css";

.custom-header-user {
  background-color: #001529;
  color: white;
}

.custom-selected-item,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f0f2f5;
}

.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark
  .ant-menu-item-selected
  > span
  > a:hover
  .ant-menu-dark
  .ant-menu-item-selected
  .anticon {
  color: #001529;
}

.logo {
  height: 9vh;
  background-image: url("../img/oasis-logo-h.png");
  background-position: initial;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 10px 15px 0px;
}

.ant-layout-sider-collapsed .logo {
  height: 9vh;
  background-image: url("../img/logo-oasis-ico.png");
  margin: 20px 15px 0px;
}

.enabled-row td {
  background-color: #ff7171;
  color: #001529;
}

.enabled-row td.ant-table-cell-row-hover {
  background-color: #ff4747 !important;
  color: #001529 !important;
}

.oasis-button-primary {
  border: #0d0630;
  background: linear-gradient(269.26deg, #172b4d 99%, #00aa96 1%);
  color: #fff;
}

.oasis-button-primary:hover {
  border: #0d0630;
  background: linear-gradient(269.26deg, #172b4d 2%, #00aa96 98%);
  color: #fff;
}

.ant-input-number {
  width: 100% !important;
}

.custom-table {
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
}
.editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.preview {
  padding: 1rem;
  margin-top: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.og-notifications .icon {
  font-size: xx-large;
}
