body {
  background-color: #172b4d;
}

.oasis-background-1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 52%;
  background: linear-gradient(269.26deg, #00aa96 5.59%, #0d0630 95.04%);
  clip-path: polygon(0 0, 104% 0, 115% 59%, 0 98%);
  z-index: -1;
}

.oasis-background-2 {
  position: absolute;
  top: 38%;
  width: 100%;
  height: 60vh;
  background: radial-gradient(
    64.65% 109.1% at 55% -20.93%,
    #1e3a6a 15.1%,
    #0d0630 100%
  );
  translate: 0px -48px;
  z-index: -2;
}

.oasis-image-cover {
  background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(/images/bg1.jpeg);
  background-size: cover;
  background-position: 0 0;
  padding: 50px 10px;
  height: 20vh;
}

.oasis-image-grid {
  display: grid !important;
  grid-template-columns: repeat(2, 2fr);
  gap: 0px;
  grid-auto-rows: 6vh;
  grid-template-areas:
    "a b"
    "c d";
}

.oasis-item-d {
  grid-area: d;
  align-self: end;
  justify-self: end;
}

.oasis-image-logo {
  width: 60%;
}
